<template>
  <div class="box-dialog">
    <el-dialog
      title="待审核收款"
      :visible.sync="selfShow"
      :close-on-click-modal="false"
      width="1200px"
    >
      <vxe-grid
        ref="refTable"
        :size="tableConfig.size"
        :border="tableConfig.border"
        :min-height="tableConfig.minHeight"
        :header-cell-class-name="cellClassName"
        :toolbar-config="tableConfig.toolbarConfig"
        :edit-config="tableConfig.editConfig"
        :row-config="tableConfig.rowConfig"
        :mouse-config="tableConfig.mouseConfig"
        :keyboard-config="tableConfig.keyboardConfig"
        :checkbox-config="tableConfig.checkboxConfig"
        :columns="tableColumn"
        :data="listData"
        :show-footer="!!listData.length"
      >
        <template #default_create_time="{ row }">
          {{ row.create_time && row.create_time.slice(0, 10) }}
        </template>
        <template #edit_funds_received="{ row }">
          <el-input v-model="row.funds_received" type="number" size="mini" clearable />
        </template>
        <template #default_cash_photo_list="{ row }">
          <e-upload-image
            v-if="row.cash_photo_list"
            size="30px"
            disabled
            :list.sync="row.cash_photo_list"
          />
        </template>
        <template #default_cash_status="{ row }">
          <span v-if="row.cash_status === '待审核'" :style="{ color: '#999' }">
            {{ row.cash_status }}
          </span>
          <span v-if="row.cash_status === '已提交'" :style="{ color: '#66b1ff' }">
            {{ row.cash_status }}
          </span>
          <span v-if="row.cash_status === '已审核'" :style="{ color: '#67c23a' }">
            {{ row.cash_status }}
          </span>
        </template>
        <template #default_operate="{ row, rowIndex }">
          <el-button size="mini" type="danger" @click="itemDelete(row.id, rowIndex)">
            删除
          </el-button>
        </template>
      </vxe-grid>
      <div slot="footer">
        <el-button size="small" @click="popupCancel">取消</el-button>
        <el-button size="small" type="primary" @click="popupConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    detailId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      tableColumn: [
        {
          fixed: 'left',
          type: 'checkbox',
          width: 40
        },
        {
          title: '序号',
          type: 'seq',
          width: 50
        },
        {
          title: '收款日期',
          field: 'create_time',
          slots: { default: 'default_create_time' }
        },
        {
          title: '收款单号',
          field: 'id'
        },
        {
          title: '收款账户',
          field: 'bank_name',
          width: 160
        },
        {
          title: '收款金额(元)',
          field: 'amount_received'
        },
        {
          title: '实收金额(元)',
          field: 'funds_received',
          width: 100,
          slots: { edit: 'edit_funds_received' },
          editRender: { autofocus: '.el-input__inner' }
        },
        {
          title: '收款类型',
          field: 'collection_type'
        },
        {
          title: '客户付款凭证',
          field: 'cash_photo_list',
          width: 200,
          slots: { default: 'default_cash_photo_list' }
        },
        {
          title: '收款备注',
          field: 'remark'
        },
        {
          title: '收款状态',
          field: 'cash_status',
          slots: { default: 'default_cash_status' }
        },
        {
          title: '操作',
          slots: { default: 'default_operate' }
        }
      ],

      listData: []
    }
  },
  computed: {
    // 表格配置合并
    tableConfig() {
      let disabled = this.disabled
      let obj = {
        editConfig: {
          beforeEditMethod() {
            return !disabled
          }
        },
        keyboardConfig: {
          isEdit: !disabled
        },
        checkboxConfig: {
          checkMethod: ({ row }) => {
            return row.cash_status === '已提交'
          },
          visibleMethod({ row }) {
            return row.cash_status === '已提交'
          }
        }
      }
      let result = this.$util.deepMerge(this.$constant.tableDefaultConfig, obj)
      return result
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    this.getDetailData()
  },
  methods: {
    // 获取-详情数据
    getDetailData() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/reportBill/billCashById',
        params: {
          id: this.detailId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.listData = this.$util.deepCopy(res.data.collection_list)
          this.listData.forEach((item) => {
            if (!item.funds_received) item.funds_received = item.amount_received
            if (item.cash_status === '已提交') {
              this.$nextTick(() => {
                this.$refs.refTable.setCheckboxRow([item], true) // 默认选中已提交状态的所有收款单
              })
            }
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 表格单元格样式设置
    cellClassName({ column }) {
      if (column.slots && column.slots.edit) return 'edit'

      return null
    },
    // 项-选择提示
    itemChoosePrompt() {
      this.$tool.tableChoosePrompt()
    },
    // 获取-当前复选行
    getCheckboxRecords() {
      return this.$refs.refTable.getCheckboxRecords()
    },
    // 项-删除
    itemDelete(id, rowIndex) {
      // this.getDetailData()
      this.$api({
        method: 'post',
        url: '/admin/gcvip/reportBill/deleteByCash',
        params: {
          id
        }
      })
        .then((res) => {
          if (res.data.state === 'ok') {
            this.$message.success('删除成功')
            this.listData.splice(rowIndex, 1)
            this.$emit('success')
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {})
    },
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      let row = this.getCheckboxRecords()
      if (row.length) {
        // 判断选中行实收金额是否都>0
        let arr = row.map((item) => {
          return {
            id: item.id,
            funds_received: item.funds_received
          }
        })
        let isPass = arr.every((item) => !!Number(item.funds_received))
        if (!isPass) {
          this.$message.error('选中行的实收金额必须>0')
          return
        }
        // 提交选中行数据
        this.$api({
          method: 'post',
          url: '/admin/gcvip/reportBill/okByCash',
          data: {
            list: arr
          }
        })
          .then((res) => {
            if (res.data.state === 'ok') {
              this.popupCancel()
              this.$emit('success')
              this.$message.success('审核成功')
            } else {
              this.$message.error(res.data.msg)
            }
          })
          .catch((error) => {
            this.$message.error(error)
          })
      } else {
        this.itemChoosePrompt()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.box-dialog {
}
</style>
